export const checkValidity = (value, rules) => {
  /*
        Check the validity of value with the rules
    */

  let validateModule = {
    isValid: true,
    message: "",
  };

  if (!rules) {
    return validateModule;
  }

  if (rules.required) {
    validateModule.isValid = value.trim() !== "" && validateModule.isValid;
    if (!(value.trim() !== "")) {
      validateModule.message = "This field is required!";
    }
  }

  if (rules.minLength) {
    validateModule.isValid =
      value.length >= rules.minLength && validateModule.isValid;
    if (!(value.length >= rules.minLength)) {
      validateModule.message =
        "This field requires a minimum length of " + rules.minLength.toString();
    }
  }

  if (rules.maxLength) {
    validateModule.isValid =
      value.length <= rules.maxLength && validateModule.isValid;
    if (!(value.length <= rules.maxLength)) {
      validateModule.message =
        "Max character length of " + rules.maxLength.toString() + " reached!";
    }
  }

  if (rules.isEmail) {
    /* See
     * https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address */
    const pattern =
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    validateModule.isValid = pattern.test(value) && validateModule.isValid;
    if (!pattern.test(value)) {
      validateModule.message = "Please enter a valid email!";
    }
  }

  if (rules.isStrictText) {
    const pattern = /^[A-Za-z ]+$/;
    validateModule.isValid = pattern.test(value) && validateModule.isValid;
    if (!pattern.test(value)) {
      validateModule.message =
        "Please only use characters from A-Z and/or a-z!";
    }
  }

  if (rules.isNumber) {
    const pattern = /^\d+$/;
    validateModule.isValid = pattern.test(value) && validateModule.isValid;
    if (!pattern.test(value)) {
      validateModule.message = "Please enter a valid numerical number!";
    } else {
      if (value < 0) {
        validateModule.isValid = false;
        validateModule.message = "Number should not be negative!";
      }
    }
  }

  if (rules.isDecimal) {
    const patternWHOLE = /^\d+$/;
    const patternDECIMAL = /^\d+\.\d+$/;
    validateModule.isValid =
      (patternWHOLE.test(value) || patternDECIMAL.test(value)) &&
      validateModule.isValid;
    if (!(patternWHOLE.test(value) || patternDECIMAL.test(value))) {
      validateModule.message = "Please enter a valid numerical number!";
    } else {
      if (value < 0) {
        validateModule.isValid = false;
        validateModule.message = "Number should not be negative!";
      }
    }
  }

  if (rules.isFile) {
    if (value > 8000000) {
      validateModule.isValid = false;
      validateModule.message = "File size should be 8 megabytes or less!";
    }
  }

  if (validateModule.isValid && rules.maxLength) {
    let char_remain = rules.maxLength - value.length;
    validateModule.message = char_remain.toString() + " characters left";
  }

  return validateModule;
};

export const formValidity = (value, rules) => {
  /* Check validity for submission forms */

  let validateModule = {
    isValid: true,
    message: "",
  };

  if (!rules) {
    return validateModule;
  }

  if (rules.required) {
    if (typeof value === "object") {
      // Only work for array?
      validateModule.isValid = value.length > 0 && validateModule.isValid;
      if (value.length === 0) {
        validateModule.message = "This field is required!";
      }
    } else {
      validateModule.isValid =
        value.trim() !== "" &&
        value.trim() !== "none" &&
        validateModule.isValid;
      if (!(value.trim() !== "")) {
        validateModule.message = "This field is required!";
      }
    }
  } else {
    validateModule.message = "(optional field)";
  }

  if (rules.minLength) {
    validateModule.isValid =
      value.length >= rules.minLength && validateModule.isValid;
    if (!(value.length >= rules.minLength)) {
      validateModule.message =
        "This field requires a minimum length of " + rules.minLength.toString();
    }
  }

  if (rules.maxLength) {
    validateModule.isValid =
      value.length <= rules.maxLength && validateModule.isValid;
    if (!(value.length <= rules.maxLength)) {
      validateModule.message =
        "Max character length of " + rules.maxLength.toString() + " reached!";
    }
  }

  if (rules.isEmail) {
    /* See
     * https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address */
    const pattern =
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    validateModule.isValid = pattern.test(value) && validateModule.isValid;
    if (!pattern.test(value)) {
      validateModule.message = "Please enter a valid email!";
    }
  }

  if (rules.isStrictText) {
    const pattern = /^[A-Za-z ]+$/;
    validateModule.isValid = pattern.test(value) && validateModule.isValid;
    if (!pattern.test(value)) {
      validateModule.message =
        "Please only use characters from A-Z and/or a-z!";
    }
  }

  if (rules.isNumber) {
    const pattern = /^\d+$/;
    validateModule.isValid = pattern.test(value) && validateModule.isValid;
    if (!pattern.test(value)) {
      validateModule.message = "Please enter a valid numerical number!";
    } else {
      if (value < 0) {
        validateModule.isValid = false;
        validateModule.message = "Number should not be negative!";
      }
    }
  }

  if (rules.isFile) {
    if (value > 8000000) {
      validateModule.isValid = false;
      validateModule.message = "File size should be 8 megabytes or less!";
    }
  }

  if (validateModule.isValid && rules.maxLength) {
    let char_remain = rules.maxLength - value.length;
    validateModule.message = char_remain.toString() + " characters left";
  }

  return validateModule;
};

export const checkMatchValidity = (target, source, name) => {
  let validateModule = {
    isValid: true,
    message: "",
  };

  if (source !== target) {
    validateModule.isValid = false;
    validateModule.message = name + " does not match!";
  }

  if (name === "Emails") {
    /* See
     * https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address */
    const pattern =
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    validateModule.isValid = pattern.test(target) && validateModule.isValid;
    if (!pattern.test(target)) {
      validateModule.message = "Please enter a valid email!";
    }
  }

  return validateModule;
};

export const decimalFormatting = new Intl.NumberFormat("en", {
  style: "decimal",
  currency: "SGD",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  useGrouping: true,
});
